@import "style/utils";
@import "style/animations";

.root {
  @include DesktopPadding;
  @include Inset(0);

  position: fixed;
  z-index: 3;
}

.show {
  animation: 0.3s ease-out forwards appearence;
}

.hide {
  animation: 0.3s ease-out forwards hiding;
}
