//Helvetica

@mixin Head1Bold {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.078px;
}

@mixin Head1Reg {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.078px;
}

@mixin Head2Bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

@mixin Body1Bold {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.078px;
}

@mixin Body1Med {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.078px;
}

@mixin Body1Reg {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.078px;
}

@mixin Body2Bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

@mixin Body2Med {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

@mixin Body2Reg {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.078px;
}

@mixin Small1Bold {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.078px;
}

@mixin Small1Med {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.078px;
}

@mixin Text1Reg {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.078px;
}

//T-star

@mixin THeavy16 {
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

@mixin TBold16 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

@mixin TMed16Crossed {
  font-size: 16px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  text-decoration-line: line-through;
}

@mixin TMed16 {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

@mixin TMed14 {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

@mixin THeavy12 {
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin TBold12 {
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin TMed12 {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin THeavy10 {
  font-weight: 900;
  font-size: 10px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin TBold10 {
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin TBold10Crossed {
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
  text-decoration-line: line-through;
}

@mixin TMed10 {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin TBold9 {
  font-weight: 700;
  font-size: 9px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin TBold8 {
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
}

@mixin TBold8Crossed {
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 2.3px;
  text-transform: uppercase;
  text-decoration-line: line-through;
}
