.backBtnRoot {
  display: flex;
  align-items: center;
  color: #007aff;
}

.label {
  margin-left: 4px;
  font-size: 14px;
  letter-spacing: -0.0241em;
}
