@import "style/colors";
@import "style/typo-mixins";
@import "style/utils";

.root {
  @include THeavy10;
  @include FlexCentered;

  width: 100%;
  height: 100%;
  color: $Gray44;
  text-align: center;
}
