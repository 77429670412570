.menu {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  li {
    margin: 0 12px 0 0;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:last-child {
      margin-right: 0;
    }

    &[class="active"] {
      opacity: 1;
    }
  }
}
