@import "style/utils";

.popupContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 12;
  height: 100%;
  max-height: 100vh;
  padding: 0 $DesktopPaddingHorizontalValue;
  background-color: white;
}

.popupHeader {
  position: sticky;
  top: 0;
  left: 0;
  margin-bottom: 12px;
}

.popupContent {
  max-height: 100%;
}
