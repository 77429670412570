.root {
  height: 28px;
  padding: 0 9px;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #007aff;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.0241em;
}
