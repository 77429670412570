.section {
  margin-bottom: 12px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 15px;
    font-size: 13px;
  }

  &__headerLeft {
    display: flex;
  }

  &__ava {
    width: 42px;
    min-width: 42px;
    height: 42px;
    margin-right: 9px;
    border-radius: 50%;
    background-color: #ededed;

    img {
      display: block;
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
  }

  &__title {
    margin-bottom: 5px;
    color: #000000;
    font-weight: 600;
    letter-spacing: -0.15px;
  }

  &__count {
    opacity: 0.5;
  }
}

.buttonsContainer {
  display: flex;
}

.addButton {
  min-width: 24px;
  margin-left: 27px;
}
