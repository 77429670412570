@import "style/utils";

.root {
  @include DesktopMediaQuery {
    margin: 0 $DesktopPaddingHorizontalValue;
  }

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 12px;
  background-color: #e6e6e8;
}

.centerElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
