.optionsContainer {
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  padding: 0 10px;
  border-radius: 14px;
  background-color: #e5e5e5;
}

.optionsItem {
  width: 100%;
  padding: 10px 0;
  color: black;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0241em;
  text-align: left;
  white-space: nowrap;

  &:not(:last-child) {
    border-bottom: 1px solid #b4b4b6;
  }
}
