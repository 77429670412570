@import "style/utils";

.root {
  position: relative;
}

.video {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.muteButton {
  position: absolute;
  right: 0;
  bottom: 0;
}
