@import "style/colors";

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  height: 66px;
  background-color: $White-40-84;
  backdrop-filter: blur(50px);
}

.headerCenter {
  position: absolute;
  left: 50%;
  padding: 30px 0 26px;
  transform: translateX(-50%);
}

.headerCenterWithoutBackButton {
  margin-left: 20px;
  padding: 30px 0 26px;
}

.headerRight {
  display: flex;
}

.logo {
  margin-left: 20px;
}

.title {
  position: absolute;
  top: 16px;
  left: 50%;
  color: $Gray42;
  font-size: 12px;
  white-space: nowrap;
  transform: translateX(-50%);
}
