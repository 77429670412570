.itemEmpty {
  background: rgba(196, 196, 196, 0.3);
}

.item {
  position: relative;
  padding-bottom: 100%;
  touch-action: manipulation;
  -webkit-touch-callout: none;
}
