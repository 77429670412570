@import "style/colors";
@import "style/utils";

.root {
  min-height: min(125vw, calc($DesktopMaxWidth * 1.25));

  div[class~="swiper-pagination"] {
    left: 50%;
    width: fit-content;
    transform: translateX(-50%);
  }
}

.slide {
  background-color: $Gray48;
}
