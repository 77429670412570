@import "style/colors";
@import "style/typo-mixins";
@import "style/utils";

.root {
  @include FlexCentered;

  position: relative;
  padding: 20px 8px 30px;
}

.counter {
  @include FlexCentered;
  @include TBold8;

  position: absolute;
  bottom: 16px;
  color: $Gray43;
  letter-spacing: normal;
}
