.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.bluredBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}

.hidden {
  visibility: hidden;
}

.fullscreen {
  position: fixed;
}
