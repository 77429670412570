.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3px;

  &__empty {
    grid-column: 1 / 4;
  }
}
