@import "style/colors";
@import "style/typo-mixins";
@import "style/utils";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $White-40-84;
  color: $Gray43;
  text-transform: uppercase;
  backdrop-filter: blur(50px);
}

.topBar {
  position: relative;
  z-index: 3;
}

.bottomBar {
  padding: 12px 8px;
}

.justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.searchButton {
  padding: 16px !important;
}

.closeButton {
  display: inline-block;
  width: fit-content;
  padding: 16px;

  img {
    width: 16px;
  }
}

.infoMenu {
  position: absolute;
  top: 100%;
  right: 32px;
  overflow: hidden;
  min-width: 180px;
  max-height: 0;
  margin-top: -16px;
  text-align: right;
  transition: max-height .3s ease-in-out;

  a {
    @include TBold12;

    display: block;
    padding: 6px 0;
    color: $Black40;
  }
}

.infoToggle {
  @include TBold12;

  padding: 16px;
  color: $Black40;
  cursor: pointer;

  &::before {
    content: "+ ";
  }

  &.opened {
    @include THeavy12;

    &::before {
      content: "- ";
    }

    & + .infoMenu {
      max-height: 200px;
    }
  }
}

.chatLink {
  @include TBold10;

  color: $Black40;
  cursor: pointer;
}

.profileLink {
  @include TBold12;

  display: flex;
  color: $Black40;

  img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
}

.content {
  flex-grow: 1;
  overflow: auto;
  padding: 32px;
  text-align: center;
}