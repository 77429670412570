.selectionIcon {
  position: absolute;
  top: 9px;
  right: 9px;
  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 24px;
  height: 24px;
  border: 1.5px solid #fff;
  border-radius: 24px;

  span {
    color: #ffffff;
    font-weight: normal;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.154px;
  }

  &.active {
    z-index: 2;
    display: flex;
  }

  &.selected {
    background: #007aff;
  }
}
