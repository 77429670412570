@import "style/colors";
@import "style/typo-mixins";

.root {
  display: flex;
}

.clearText {
  padding: 22px 20px;

  img {
    width: 12px;
    height: 12px;
  }
}

.inputContainer {
  position: relative;
  width: 100%;
}

.searcIcon {
  position: absolute;
  top: 21px;
  left: 0;
}

.searchInput {
  @include TBold16;

  width: 100%;
  height: 56px;
  padding-left: 24px;
  border-radius: 25px;
  color: $Gray43;

  &::placeholder {
    color: $Gray47;
  }
}

.backButton {
  padding: 20px 24px 20px 20px;
}
