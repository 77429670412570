@import "style/colors";

.root {
  padding: 17px;
  background-color: $Gray47;
  transition: all 0.3s ease-out;

  &:active:not(:disabled) {
    transform: scale(0.9);
  }

  svg {
    transform: rotate(90deg);
  }
}
