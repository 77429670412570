@import "style/colors";
@import "style/typo-mixins";

.link {
  display: block;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.toggler {
  cursor: pointer;
}

.subList {
  overflow: hidden;
  max-height: 0;
  padding: 0 0 0 16px;
  transition: max-height .3s ease-in-out, padding .2s ease-in-out;

  .toggler, .link {
    height: 22px;
    line-height: 22px;
  }
}

.item {
  @include TBold12;

  color: $Black40;
  text-align: left;

  &.opened {
    .subList {
      padding-top: 4px;
      padding-bottom: 16px;
    }

    & > .link {
      @include THeavy12;
    }
  }

  &.highlighted {
    color: $DarkRed;
  }
}