@import "style/colors";
@import "style/utils";

.root {
  @include DesktopPadding;
  @include HideScrollBar;
  @include Inset(0);

  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: $White40;
}

.stickyContainer {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: $White-40-84;
  backdrop-filter: blur(50px);
}
