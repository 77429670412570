@import "style/utils.scss";
@import "style/colors";

.root {
  @include FlexCentered;

  padding: 27px 16px 23px;

  img {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
}
