$DesktopMaxWidth: 700px;
$ExpandedBlockWidth: min(100vw, $DesktopMaxWidth);
$ExpandedBlockHeight: min(125vw, calc($DesktopMaxWidth * 1.25));
$DesktopPaddingHorizontalValue: calc((100% - $DesktopMaxWidth) / 2);
$CardDimensionalUnit: min(
  calc(($DesktopMaxWidth - 25px) / 8),
  calc((100vw - 25px) / 8)
);

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
}

.container {
  width: 100%;
  min-width: 320px;
  padding-right: 16px;
  padding-left: 16px;
}

.base-content {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  line-height: 1.3;

  h5 {
    margin: 0;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.25em;
    text-align: left;
    text-transform: uppercase;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  img {
    max-width: 100%;
  }
}

@mixin HideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin FlexCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin FlexCenteredInline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin DesktopPadding {
  padding: 0 $DesktopPaddingHorizontalValue;
}

@mixin DesktopMediaQuery {
  @media (min-width: $DesktopMaxWidth) {
    @content;
  }
}

@mixin Inset($value) {
  top: $value;
  right: $value;
  bottom: $value;
  left: $value;
}
