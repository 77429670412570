@import "style/colors";
@import "style/typo-mixins";
@import "style/utils";

.root {
  @include DesktopPadding;
  @include Inset(0);

  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: $White40;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
  margin-bottom: 10px;
}

.resultsContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
  padding: 32px 24px;
  border-radius: 30px 30px 0 0;
  background-color: $White41;
  color: $Gray43;

  button {
    @include TBold10;

    color: inherit;
    text-align: left;
  }
}
