.container {
  display: flex;

  button {
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
  }

  button:first-child {
    margin-right: 9px;
  }
}

button.active {
  opacity: 1;
}
