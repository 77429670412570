.root {
  height: 100%;
}

.productsContainer {
  padding: 16px 8px;
}

.banner {
  margin-bottom: -33px;
}
