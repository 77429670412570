.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rightSlot {
  display: flex;
}
