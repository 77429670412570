.root {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 20px;
  background-color: white;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.01em;
}

.number {
  color: #999999;
  font-weight: 500;
}
