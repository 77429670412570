.root {
  position: sticky;
  top: 0;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 58px;
  padding: 0 13px 0 6px;
  border-bottom: 1px solid #b4b4b6;
  background-color: white;
}

.rightSide {
  display: flex;
  gap: 9px;
}

.logo {
  position: absolute;
  left: 50%;
  display: block;
  width: 28px;
  height: 26px;
  transform: translateX(-50%);
}
