.banner {
  position: relative;
  display: block;
  grid-column: 1 / 3;

  // в iOS 17 (до версии 17.2) страница не скроллится,
  // если палец лежит на видео с playsInline=true
  // по этой причине сверху нужен доп. слой
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  img,
  video {
    display: block;
    width: 100%;
  }
}
