@import "style/colors";
@import "style/utils";

.contentContainer {
  position: relative;
  flex-grow: 1;

  & > div {
    height: 100%;
  }
}

.contentTab {
  @include HideScrollBar;
  @include Inset(0);

  position: absolute;
  overflow-y: auto;
  background-color: $White40;

  &.hideTab {
    z-index: -1;
  }
}

.header {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: none;
}

.calendarButton {
  @include FlexCentered;
  @include DesktopMediaQuery {
    left: calc($DesktopPaddingHorizontalValue + 24px);
  }

  position: fixed;
  bottom: 32px;
  left: 24px;
  z-index: 3;
  width: 48px;
  height: 48px;
  border-radius: 20px;
  background-color: $White40;
}

.addButton {
  @include FlexCentered;
  @include DesktopMediaQuery {
    left: calc($DesktopPaddingHorizontalValue + 24px);
  }

  position: fixed;
  bottom: 88px;
  left: 24px;
  z-index: 3;
  width: 48px;
  height: 48px;
  border-radius: 20px;
  background-color: $White40;
}

.goUp {
  @include DesktopMediaQuery {
    right: calc($DesktopPaddingHorizontalValue + 24px);
  }

  position: fixed;
  right: 24px;
  bottom: 32px;
  z-index: 3;
}
