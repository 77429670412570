@mixin ScaleAppearingBefore {
  opacity: 0;
  filter: blur(5px);
  transition: all 0.3s ease-out;
  transform: scale(0.8);
}

@mixin ScaleAppearingAfter {
  animation: scale-apearring 0.3s ease-out 0.3s forwards;
}

@keyframes scale-apearring {
  from {
    opacity: 0;
    filter: blur(5px);
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

@keyframes appearence {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes hiding {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
