$Accent: #088fad;
$Black: #000000;
$White: #ffffff;
$White-10: rgba(255, 255, 255, 0.1);
$Accent-84: rgba(8, 143, 173, 0.84);
$Black-Back: rgba(80, 80, 80, 0.8);
$Black-75: rgba(30, 30, 30, 0.75);
$Black-65: rgba(202, 202, 202, 0.65);
$Black-30: rgba(0, 0, 0, 0.3);
$Black-15: rgba(0, 0, 0, 0.15);
$Black-05: rgba(0, 0, 0, 0.05);
$White-30: rgba(255, 255, 255, 0.3);
$White-85: rgba(255, 255, 255, 0.85);
$Gray1: #ededed;
$Gray2: #626262;
$Gray3: #8f8f8f;
$Back: #afafaf;
$Back-80: rgba($Back, 0.8);
$Back-65: rgba($Back, 0.65);
$BlackBack-80: rgba(80, 80, 80, 0.8);
$Back2-75: rgba($Back, 0.75);
$Red: #ff6b74;
$Red2: #e83742;
$Red-10: rgba(255, 87, 87, 0.1);
$Error: #d8545c;
$Filter-30: rgba(102, 102, 102, 0.3);
$Separator-65: rgba(84, 84, 88, 0.65);
$Blue: #007aff;

//Online shop
$GrayErrorBlock: #e5e5e5;
$GrayBorder: #b4b4b6;
$AvatarBackground: #c4c4c4;
$BottomPopupBackground: #f5f5f5;
$ProfileGray: #b7b8ba;
$BrandColor: #d7c3b3;
$ErrorColor: #f16972;
$TextButtonColor: #808288;
$FrenchGray: #c8c7cc;
$Alabaster: #f9f9f9;

//Redesign again
$Gray41: #928f8d;
$Gray42: #d1d0cd;
$Gray43: #676560;
$Gray43-84: rgba(103, 101, 96, 0.84);
$Gray44: #b6b2b0;
$Gray45: #827f7a;
$Gray46: #9e9b99;
$White40: #eeedea;
$Back40: #aaa7a5;
$Back40-84: rgba(170, 167, 165, 0.84);
$Black40-84: rgba(29, 29, 29, 0.84);
$DarkRed: #b24d53;
$Gray47: #bdbcba;
$White-40-84: rgba(238, 237, 234, 0.84);
$Red: #e83742;
$White-40-30: rgba(238, 237, 234, 0.3);
$Gray48: #e9e7e4;
$White41: white;
$Black40: #1d1d1d;
