@import "style/colors";
@import "style/utils";

.root {
  padding: 12px;
}

.backGround {
  @include FlexCentered;

  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $Black-30;
  backdrop-filter: blur(60px);
}

.icon {
  display: inline-block;
  width: 12px;
  height: 12px;
}
