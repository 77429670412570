@import "style/colors";
@import "style/utils";
@import "style/typo-mixins";

.root {
  &:not(:first-child) {
    margin-top: 42px;
  }
}

.slides {
  display: flex;
  justify-content: space-between;
}

.imageContainer {
  position: relative;
  display: block;
  width: calc($CardDimensionalUnit * 4);
  max-width: 100%;
  margin-bottom: 16px;
  background-color: $Gray48;
  aspect-ratio: 4 / 5;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    user-select: none;
  }
}

.name {
  @include TMed10;

  margin-bottom: 4px;
  color: $Black40;
}

.price {
  @include TBold12;

  color: $Black40;

  .oldPrice {
    @include TBold8Crossed;

    margin-left: 8px;
    color: $Gray45;
  }
}

.onePlusOne {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
}

.priceContainer {
  display: flex;
}

.favoritesButton {
  margin: 0 16px 0 auto;
}
