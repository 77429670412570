.itemAdd {
  background: rgba(196, 196, 196, 0.3);

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon {
  max-width: 24px;
  max-height: 24px;
}
