.item {
  position: relative;
  padding-bottom: 100%;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    video {
      background-color: #ccc;
    }
  }
}

.playIcon {
  position: absolute;
  right: 4px;
  bottom: 6px;
  width: 20px;
  height: 20px;
}

.statusIcon {
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
}
