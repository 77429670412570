.styledCalendar {
  max-width: 348px;
  padding: 21px 12px;
  border-radius: 21px;
  background-color: white;
  font-weight: 500;
  font-size: 14px;

  [class="react-calendar__month-view__weekdays__weekday"] {
    text-align: center;

    & > abbr {
      text-decoration: none;
    }
  }

  [class*="react-calendar__navigation__label__labelText"] {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
  }

  [class="react-calendar__navigation"] {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button:last-child {
      transform: rotate(180deg);
    }
  }

  [class="react-calendar__month-view__weekdays"] {
    & > div {
      margin: 10px 0;
      padding: 8px 0;
      font-weight: 600;
      font-size: 17px;
      text-transform: capitalize;
    }
  }

  [class*="react-calendar__tile--active"] {
    background-size: 100% 100%;
    color: white;

    [class*="blockCountContainer"] {
      display: none;
    }
  }

  [class*="react-calendar__tile--now"] {
    color: black;
    font-weight: 700;
  }
}

.navigation {
  font-size: 18px;
  line-height: 21px;
}

.tile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 8px 0;
  background-image: url("../../../../public/static/img/icons/admin/calendar-active-day.svg");
  background-position: center;
  background-size: 0 0;
  background-repeat: no-repeat;
  color: #999;
  transition: background-size 0.2s ease-out;
}

.blockCountContainer {
  position: absolute;
  left: 26px;
  margin-left: 8px;
  font-size: 11px;
  line-height: 13px;
}

.blockNotFinished {
  color: #f8a7ac;
}

.blockPublished {
  color: #007aff;
}
